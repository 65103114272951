import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

import { StaticImage } from "gatsby-plugin-image";
import { getImage, GatsbyImage } from 'gatsby-plugin-image';
import Layout from "../../../components/layout";
import Seo from "../../../components/seo";
import MeterMountsImage from "../../products/huey-h2o/MeterMountsImage";
export const _frontmatter = {
  "title": "Huey H₂O Support"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">

    <Layout className="page huey-h2o-support-page" mdxType="Layout">
      <Seo title={props.pageContext.frontmatter.title} mdxType="Seo" />
      <div className="col-10 offset-1">
        <h1>{`Huey H₂O Support`}</h1>
        <h2>{`Determining Compatibility of your Water Meter`}</h2>
        <p>{`Huey currently only supports "Cyble sensor" compatible water meters, as manufactured by Itron.`}</p>
        <h2>{`Is your Meter Compatible?`}</h2>
        <ol>
          <li parentName="ol">{`Locate the water meter for your property. It is typically towards the front of the block, most likely on either side corner.`}</li>
          <li parentName="ol">{`Does your water meter already have a communication module like Huey mounted on it? If so, you won't be able to install Huey at the same  time. Your property owner and/or water utility should be able explain the reason for the existing module.`}</li>
          <li parentName="ol">{`Open the hinged meter cover that sits over the face of the meter (if present), so that you can see the rolling digits.`}</li>
          <li parentName="ol">{`Look for a brand. Do you see "Itron" eg. above the rolling digits? If so, it is compatible. If you see another brand, it is probably not  compatible.`}</li>
          <li parentName="ol">{`Look for the three Cyble mounts, as circled in red in the photo below.`}</li>
        </ol>
        <p>{`If you are unsure about the compatibility of your meter, please send us a photo of your meter, and we will let you know whether it is compatible or not.`}</p>
        <a href="/platform/huey-mobile/support">Also see Huey Mobile support</a>
        <br />
        <br />
        <div className="w-100 mx-auto">
          <MeterMountsImage mdxType="MeterMountsImage" />
        </div>
        <br />
        <br />
      </div>
    </Layout>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      